import React from 'react';
import styles from "./CV.modules.css";

const Neil_CV = () => {
  return (
    <div className={styles.clientsContainer}>
      <section className="hero-section">
        <div className="hero-content">
          <h1 className="orbitron-header">
            RES<span className="yellow-text">UME</span>
          </h1>
        </div>
      </section>
    <div className="container mt-4">
      <div className="text-center mb-5">
        <h1>NEIL BENJAMIN CV</h1>
        <h3>Graduate Full Stack Web Developer</h3>
      </div>

      <div className="row mb-4">
        <div className="col-md-4 col-12">
          <h3>M.E.R.N Application</h3>
          <h4>2023 - present</h4>
          <p><strong>DigitalBlend Website</strong></p>
          <ul>
            <li>Design, layout and user login mechanism</li>
            <li>Coded framework using M.E.R.N.</li>
            <li>Form, email functionality, and live weather API added for fun.</li>
            <li><a href='https://digitalblend.co.za/' target='_blank' rel='noopener noreferrer'>DigitalBlend</a></li>
          </ul>
          <p><strong>Skater database for Administrators and skaters alike</strong></p>
          <ul>
            <li>Concept, layout, and planning of the application</li>
            <li>Coded framework using M.E.R.N.</li>
            <li>Testing, deployment, and implementation</li>
            <li><a href="https://skaterpatchapp.onrender.com/login" target="_blank" rel='noopener noreferrer'>Log In</a> with email: admin@skaterpatch.com and password: 123</li>
          </ul>
        </div>

        <div className="col-md-4 col-12">
          <h3>CAPSTONE PROJECT</h3>
          <h4>2023 - present</h4>
          <ul>
            <li>Completed all <a href='https://www.hyperiondev.com/portfolio/NB23040008550/' target='_blank' rel='noopener noreferrer'>Capstone Projects</a></li>
            <li>Comprehensive project overview encapsulating all M.E.R.N competencies</li>
            <li>Please view the <a href='https://www.hyperiondev.com/portfolio/NB23040008550/' target='_blank' rel='noopener noreferrer'>Completed Course Content</a> for all subjects covered.</li>
          </ul>
        </div>

        <div className="col-md-4 col-12">
          <h3>WORDPRESS CDN</h3>
          <h4>2019 - present</h4>
          <p>Site design, build, SEO best-practices, and maintenance. Customizing existing WP frameworks and fully e-commerce compatible solutions.</p>
          <ul>
            <li><a href='https://eclecticfacilitators.co.za/' target='_blank' rel='noopener noreferrer'>Eclectic Facilitators</a></li>
            <li><a href='https://bonitasfemalehealth.co.za/' target='_blank' rel='noopener noreferrer'>Bonitas Female Health</a></li>
            <li><a href='https://www.hamiltongallery.co.za/' target='_blank' rel='noopener noreferrer'>Hamilton Gallery</a></li>
            <li><a href='https://www.octohoodie.com/' target='_blank' rel='noopener noreferrer'>Octohoodie</a></li>
            <li><a href='https://www.gooiensteek.com/' target='_blank' rel='noopener noreferrer'>Gooi en Steek</a></li>
            <li><a href='https://www.clintongahwiler.com/' target='_blank' rel='noopener noreferrer'>Clinton Gahwiler</a></li>
            <li><a href='https://www.capedjco.co.za/' target='_blank' rel='noopener noreferrer'>Cape DJ Co.</a></li>
            <li><a href='https://www.blacksheepmusic.co.za/' target='_blank' rel='noopener noreferrer'>Blacksheep Music</a></li>
          </ul>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-md-4 col-12">
          <h2>TECH EDUCATION</h2>
          <ul>
            <li>Full Stack Web Development - Hyperion College - 2023 - 2024</li>
            <li>Code Academy JavaScript Certification - 2022 - 2023</li>
            <li>Currently studying Dart, Flutter, MySQL, Tailwind CSS, and TypeScript</li>
          </ul>
        </div>

        <div className="col-md-4 col-12">
          <h2>TECH SKILLS</h2>
          <ul>
            <li>HTML, CSS, JavaScript, React, Node, Express, MongoDB, Next.js, Git, GitHub, JWT, Bootstrap, and more.</li>
            <li>WordPress, SEO, Adobe Illustrator, Figma Design</li>
            <li>Full Stack Certification available at <a href='https://www.hyperiondev.com/portfolio/NB23040008550/' target='_blank' rel='noopener noreferrer'>HyperionDev Portfolio</a></li>
            <li>GitHub Portfolio - <a href='https://github.com/neilbenjamin' target='_blank' rel='noopener noreferrer'>Neil Benjamin's GitHub</a></li>
          </ul>
        </div>

        <div className="col-md-4 col-12">
          <h2>SOFT SKILLS</h2>
          <ul>
            <li>Audio Post Production Engineer</li>
            <li>Musician, teacher, and music producer</li>
            <li>Event Management Company Business Owner</li>
          </ul>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-md-6 col-12">
          <h2>ACHIEVEMENTS</h2>
          <ul>
            <li>Managed a 100% average for my Full Stack Web Development Bootcamp.</li>
            <li>Double SAFTA Winner - Best Sound Design - Television - 2018 & 2019</li>
            <li>SAMA Nomination</li>
          </ul>
        </div>

        <div className="col-md-6 col-12">
          <h2>CONTACT</h2>
          <ul>
          <li>Mobile: +27 74 175 9591</li>
            <li><a href='https://digitalblend.co.za/' target='_blank' rel='noopener noreferrer'>www.digitalblend.co.za</a></li>
            <li><a href="mailto:neil@digitalblend.co.za">neil@digitalblend.co.za</a></li>
            <li><a href='https://github.com/neilbenjamin' target='_blank' rel='noopener noreferrer'>GitHub</a></li>
            <li><a href='https://www.linkedin.com/in/neil-benjamin-a955562a7/' target='_blank' rel='noopener noreferrer'>LinkedIn</a></li>
            
          </ul>
        </div>
      </div>

      <div className="d-flex justify-content-center mb-3">
        <a href="/NEIL_BENJAMIN_CV.pdf" target="_blank" rel="noopener noreferrer" download="NEIL_BENJAMIN_CV.pdf">
          <button className="btn btn-primary">Download CV</button>
        </a>
      </div>
    </div>
    </div>
  );
}

export default Neil_CV;
